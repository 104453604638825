.ig__container {
    display: flex;
    gap: 8px;
    flex-direction: column;
    position: relative;
}

.ig__stage-outer {
    overflow: hidden;
}

.ig__stage {
    width: 100%;
    height: 0;
    overflow: hidden;
    position: relative;
}

.ig__stage--no-popup .ig__stage-image-hover {
    pointer-events: none;
}

.ig__stage-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    user-select: none;
    transition: opacity .15s cubic-bezier(0.25, 1, 0.5, 1);
    pointer-events: none;
    width: 64px;
    height: 64px;
    background: #fff;
    border-radius: 50%;
}

.ig__stage:hover .ig__stage-hover {
    opacity: 1;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__stage-image-hover {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    opacity: 0;
    transition: opacity var(--fade-speed) cubic-bezier(0.25, 1, 0.5, 1);
    cursor: zoom-in;
}

.ig__stage-image-hover:hover {
    opacity: 1;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__stage-image {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: opacity var(--fade-speed) cubic-bezier(0.25, 1, 0.5, 1);
    pointer-events: none;
}

.ig__stage-image--active {
    opacity: 1;
    pointer-events: auto;
}

.ig__stage-image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.ig__stage-image--cover img {
    object-fit: cover;
}

.ig__thumbnail-container {
    display: grid;
    position: relative;
    font-size: 0;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.ig__thumbnail-container--single-line {
    display: flex;
    justify-content: center;
}

.ig__thumbnail-container--single-line .ig__thumbnail__outer {
    padding-bottom: unset;
    height: auto;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__thumbnail-container--single-line .ig__thumbnail__outer::before {
    content: '';
    padding: calc(var(--ratio-padding) / 2) 0;
    display: inline-block;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__thumbnail__outer {
    height: 0;
    padding-bottom: var(--ratio-padding);
    overflow: hidden;
    position: relative;
    width: 100%;
}

.ig__thumbnail__outer--hidden {
    display: none;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__thumbnail__outer:after {
    height: 5px;
    background-color: var(--highlight-color);
    position: absolute;
    bottom: 0;
    width: 100%;
    content: "";
    z-index: 10;
    left: 0;
    opacity: 0;
    transition: opacity var(--fade-speed) cubic-bezier(0.25, 1, 0.5, 1);
}

.ig__thumbnail__outer--active:after {
    opacity: 1;
}

.ig__thumbnail-container--single-line .ig__thumbnail__outer {
    width: 20%;
}

.ig__thumbnail__inner {
    user-select: none;
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__thumbnail {
    width: 100%;
    pointer-events: none;
    object-fit: var(--object-fit);
    height: 100%;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__popup-container {
    background-color: rgba(68, 68, 68, .6);
    opacity: 0;
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    overflow: hidden auto;
    transition: opacity 250ms cubic-bezier(0.25, 1, 0.5, 1);
    pointer-events: none;
}

.ig__popup-container--active {
    pointer-events: auto;
    opacity: 1;
}

.ig__popup__inner {
    background-color: #f6f6f6;
    text-align: center;
    position: absolute;
    width: calc(100% - 4vh);
    height: 96vh;
    left: 0;
    top: 0;
    margin: 2vh;
    box-sizing: border-box;
}

.ig__popup__close {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 48px;
    right: 48px;
    z-index: 1;
    cursor: pointer;
}

.ig__popup__arrow {
    height: 56px;
    width: 56px;
    border-radius: 50%;
    background-color: #fff;
    cursor: pointer;
    position: absolute;
    box-sizing: border-box;
    top: 50%;
    transform: translateY(-50%);
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
}

.ig__popup__arrow--left {
    left: 24px;
}

.ig__popup__arrow--right {
    right: 24px;
}

.ig__popup__stage {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__popup__stage-image {
    opacity: 0;
    transition: opacity var(--fade-speed) cubic-bezier(0.25, 1, 0.5, 1);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.ig__popup__stage-image--active {
    opacity: 1;
}

.ig__popup__stage-image img {
    max-width: calc(100% - 4vw);
    max-height: calc(100% - 4vh);
}

.ig__carousel {
    position: absolute;
    transform: translateX(-50%);
    border-radius: 40px;
    padding: 8px 16px;
    z-index: 2;
    display: flex;
    left: 50%;
}

.ig__carousel--mobile {
    bottom: 0;
    display: none;
    justify-content: center;
}

.ig__carousel--popup {
    bottom: 24px;
    background-color: #FFF;
}

.ig__carousel__indicator-outer {
    padding: 8px;
    cursor: pointer;
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__carousel__indicator {
    display: block;
    width: 6px;
    height: 6px;
    background-color: var(--highlight-color);
    opacity: 0.5;
    border-radius: 50%;
    transform: scale3d(1, 1, 1);
    transition: transform .15s cubic-bezier(0.25, 1, 0.5, 1), opacity 0.15s cubic-bezier(0.25, 1, 0.5, 1);
    user-select: none;
}

@media (hover: hover) {
    /*noinspection CssUnresolvedCustomProperty*/
    .ig__carousel__indicator-outer:hover .ig__carousel__indicator {
        transform: scale3d(1.4, 1.4, 1.4);
        background-color: var(--highlight-color);
        opacity: 1;
    }
}

/*noinspection CssUnresolvedCustomProperty*/
.ig__carousel__indicator-outer--active .ig__carousel__indicator {
    transform: scale3d(1.4, 1.4, 1.4);
    background-color: var(--highlight-color);
    opacity: 1;
}

@media all and (max-width: 740px) {
    .ig__carousel--mobile {
        display: inherit;
        width: 90%;
        flex-wrap: wrap;
    }

    .ig__thumbnail-container--mobile-hidden {
        display: none;
    }

    .ig__stage-image-hover {
        display: none;
        pointer-events: none;
    }

    .ig__stage {
        display: flex;
        flex-wrap: nowrap;
        overflow: visible;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .ig__stage-image {
        opacity: 1;
        pointer-events: auto;
    }

    .ig__stage-image {
        height: auto;
        flex: 0 0 auto;
    }

    .ig__stage-image img {
        height: auto;
    }

    .ig__stage-image {
        position: relative;
    }
}
